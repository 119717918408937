.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar {
  background-color: #33353b;
  font-family: Helvetica;
  font-size: large;
  text-decoration: none;
  color: white;
}

.activeStyle {
  color: white;
}

#profile {
  height: 90vh;
  margin: 10%;
}

#aboutme {
  height: 100vh;
  position: relative;
  background-color: white;
  left:0;
  right:0;
  text-align: left;
  margin: 5%;
  padding: .5rem 1rem .5rem 1rem;
}

.head1 {
  font-size: 1.5em;
  font-family: Helvetica;
}

.head2 {
  font-size: 1em;
  font-family: Helvetica;
}

#projects {
  height: 100vh;
  margin: 5%;
  padding: .5rem 1rem .5rem 1rem;
}

#skills {
  height: 100vh;
  margin: 5%;
  padding: .5rem 1rem .5rem 1rem;
  background-color: #282c34;
  text-align: left;
  color:lightgrey;
}

.a {
  text-decoration: none;
  color: white;
  margin: 10;
}

a:visited { text-decoration: none; color:white; }
a:hover { text-decoration: none; color:white; }
a:focus { text-decoration: none; color:white; }
a:hover, a:active { text-decoration: none; color:white }

.btn {
  text-decoration: none;
  outline: none;
  padding: 2rem 2rem 2rem 2rem;
  margin: 1rem 1rem 1rem 1rem;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: .7em;
}

#Footer {
  color: white;
  background-color: #33353b;
}

.navbar-brand {
  color: #d6d6d6;
  font-size: 1.5rem;
  right: auto;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
